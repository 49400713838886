import { Loading } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import ErrorMessage from '../components/ErrorMessage/ErrorMessage';
import { useAnalytics } from '../components/providers/AnalyticsProvider';
import { AnalyticEvents } from '../constants/analytic-events';

function Error({
  statusCode,
  message,
  stack,
}: ReturnType<typeof Error.getInitialProps>) {
  const [i18n] = useI18n();
  const router = useRouter();
  const { trackEvent } = useAnalytics();

  const [isLoading, setIsLoading] = useState(true);

  const handleClickBack = useCallback(() => {
    router.reload();
    setIsLoading(true);
  }, [router]);

  useEffect(() => {
    trackEvent(AnalyticEvents.FRONTEND_ERROR, {
      statusCode,
      message,
      stack,
      asPath: router.asPath,
    });
  }, [message, router.asPath, stack, statusCode, trackEvent]);

  useEffect(() => {
    if (
      statusCode === -1 &&
      (message === "Cannot read properties of undefined (reading 'call')" ||
        message?.indexOf('Minified React error #130') >= 0)
    ) {
      router.reload();
    } else {
      setIsLoading(false);
    }
  }, [message, router, statusCode]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <ErrorMessage
          title={i18n.translate('Common.Error.500.title')}
          message={i18n.translate('Common.Error.500.message', { statusCode })}
          image={500}
          buttonText={i18n.translate('Common.Error.500.button')}
          onClick={handleClickBack}
        />
      )}
    </>
  );
}

Error.getInitialProps = ({ res, err }: NextPageContext) => {
  console.log('Error.getInitialProps', res, err);

  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  const message = err?.message;
  const stack = err?.stack;

  return { statusCode, message, stack };
};

export default Error;
