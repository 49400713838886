import _ from 'lodash';
import { DateTime, Duration, Settings } from 'luxon';
import { getWeekStartByLocale } from 'weekstart';
import { MeetyTimeUnit } from '../constants/enums';
import { TimezoneFullDto } from '../types/master-data-type';

export function getPreviousWeek(date: DateTime): DateTime {
  const { year, month, day } = date;
  return DateTime.fromObject({ year, month, day }).minus({ week: 1 });
}

export function getNextWeek(date: DateTime): DateTime {
  const { year, month, day } = date;
  return DateTime.fromObject({ year, month, day }).plus({ week: 1 });
}

export function getPreviousMonth(date: DateTime): DateTime {
  const { year, month, day } = date;
  return DateTime.fromObject({ year, month, day }).minus({ month: 1 });
}

export function getNextMonth(date: DateTime): DateTime {
  const { year, month, day } = date;
  return DateTime.fromObject({ year, month, day }).plus({ month: 1 });
}

export function getAllDaysInWeek(date: DateTime) {
  const days = new Array<DateTime>(date);
  const weekFirstDay = getWeekFirstDay(Settings.defaultLocale);

  let firstDay = _.first(days);
  while (firstDay && firstDay.weekday !== weekFirstDay) {
    days.unshift(getPreviousDate(firstDay));
    firstDay = _.first(days);
  }

  let lastDay = _.last(days);
  while (lastDay && lastDay.weekday % 7 !== weekFirstDay - 1) {
    days.push(getNextDate(lastDay));
    lastDay = _.last(days);
  }

  return days;
}

export function getHoursInDay(
  locale: string,
  timeFormat24h: boolean
): string[] {
  const date = DateTime.local({ locale });
  return Array.from({ length: 24 }, (_, i) => i).map((hour) => {
    return date
      .set({ hour, minute: 0 })
      .toLocaleString(
        getDateTimeFormatOptions(DateTime.TIME_SIMPLE, timeFormat24h)
      );
  });
}

export function getDateTimeFormatOptions(
  formatOpts: Intl.DateTimeFormatOptions,
  timeFormat24h: boolean
): Intl.DateTimeFormatOptions {
  return {
    ...formatOpts,
    hourCycle: timeFormat24h ? 'h23' : 'h12',
  };
}

export function getUnixTimestamp(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}

export function getStartOfToday(): DateTime {
  return DateTime.now().startOf('day');
}

export function formatTime(dateTime: DateTime, is24Hours: boolean): string {
  return dateTime.toFormat(is24Hours ? 'T' : 't');
}

export function getTimeStamp(dateTime: string): number {
  const duration = Duration.fromISOTime(dateTime);
  if (!duration.isValid) {
    return 0;
  }

  return DateTime.now().startOf('day').plus(duration).toSeconds();
}

export const getTimeZoneByOffset = (
  second: number,
  tz: 'UTC' | 'GMT' = 'UTC'
) => {
  const M = Math.abs(Math.floor(second / 60));
  const h = Math.floor(M / 60);
  const m = Math.floor(M % 60);
  // GMT+10:30
  const d = `${tz}${second > 0 ? '+' : '-'}${h < 10 ? `0${h}` : h}:${
    m < 10 ? `0${m}` : m
  }`;
  return d;
};

export const getMinute = (value: number, type: MeetyTimeUnit) => {
  const currentRate = getRate(type);
  return value * currentRate;
};

export const getValue = (minutes: number, type: MeetyTimeUnit) => {
  const currentRate = getRate(type);
  return minutes / currentRate;
};

export const getRate = (type: MeetyTimeUnit) => {
  switch (type) {
    case MeetyTimeUnit.Week:
      return 24 * 60 * 7;
    case MeetyTimeUnit.Month:
      return 24 * 60 * 30;
    case MeetyTimeUnit.Hour:
      return 60;
    case MeetyTimeUnit.Day:
      return 24 * 60;
    case MeetyTimeUnit.Minute:
      return 1;
  }
  return 1;
};

export const convertValue = (
  value: number,
  fromType: MeetyTimeUnit,
  toType: MeetyTimeUnit
) => {
  if (Number(value) == 0) {
    return 0;
  }
  const currentRate = getRate(fromType);
  const newRate = getRate(toType);
  if (currentRate != newRate) {
    return (Number(value) * currentRate) / newRate;
  }
  return Number(value);
};

export function getAllDaysInMonthWithSupplement(
  date: DateTime,
  zone: string
): DateTime[] {
  const year = date.year;
  const month = date.month;
  const daysInMonth = getAllDaysInMonth(year, month, zone);
  const weekFirstDay = getWeekFirstDay(Settings.defaultLocale);

  let firstDay: DateTime;
  while ((firstDay = daysInMonth[0]).weekday !== weekFirstDay) {
    daysInMonth.unshift(getPreviousDate(firstDay));
  }

  let lastDay: DateTime;
  while (
    (lastDay = daysInMonth[daysInMonth.length - 1]).weekday % 7 !==
    weekFirstDay - 1
  ) {
    daysInMonth.push(getNextDate(lastDay));
  }

  return daysInMonth;
}

export function getFirstAndLastDaysOfMonthWithSupplement(
  date: DateTime,
  zone: string
): [DateTime, DateTime] {
  const year = date.year;
  const month = date.month;
  const weekFirstDay = getWeekFirstDay(Settings.defaultLocale);

  let firstDay = getDate(year, month, 1, zone);
  while (firstDay.weekday !== weekFirstDay) {
    firstDay = getPreviousDate(firstDay);
  }

  let lastDay = getDate(year, month, date.daysInMonth ?? 30, zone);
  while (lastDay.weekday % 7 !== weekFirstDay - 1) {
    lastDay = getNextDate(lastDay);
  }

  return [firstDay.startOf('day'), lastDay.endOf('day')];
}

export function getFirstAndLastDaysOfMonthsWithSupplement(
  monthCount: number,
  date: DateTime,
  zone: string
): [DateTime, DateTime] {
  if (monthCount <= 1) {
    return getFirstAndLastDaysOfMonthWithSupplement(date, zone);
  } else {
    const [first] = getFirstAndLastDaysOfMonthWithSupplement(date, zone);
    const [, last] = getFirstAndLastDaysOfMonthWithSupplement(
      date.plus({ month: monthCount - 1 }),
      zone
    );
    return [first, last];
  }
}

export function getDate(
  year: number,
  month: number,
  day: number,
  zone: string
): DateTime {
  return DateTime.fromObject({ year, month, day }, { zone });
}

export function getPreviousDate(date: DateTime): DateTime {
  return date.minus({ day: 1 });
}

export function getNextDate(date: DateTime): DateTime {
  return date.plus({ day: 1 });
}

export function getAllDaysInMonth(
  year: number,
  month: number,
  zone: string
): DateTime[] {
  const date = getDate(year, month, 1, zone);
  return Array.from({ length: date.daysInMonth ?? 30 }, (_, i) =>
    getDate(year, month, i + 1, zone)
  );
}

export function getWeekFirstDay(locale?: string): number {
  const lc = locale ?? 'en';
  const intlLocale = new Intl.Locale(lc) as unknown as {
    getWeekInfo: () => { firstDay: number };
    weekInfo: { firstDay: number };
  };
  const { firstDay = 1 } = intlLocale.getWeekInfo
    ? intlLocale.getWeekInfo()
    : intlLocale.weekInfo
    ? intlLocale.weekInfo
    : { firstDay: [7, 1, 2, 3, 4, 5, 6][getWeekStartByLocale(lc)] };

  return firstDay;
}

export function isInDst(
  applyDst: number,
  timezoneInfos: TimezoneFullDto[],
  checkTime: number
): boolean {
  if (!applyDst) {
    return false;
  }
  if (timezoneInfos) {
    const tzs = timezoneInfos?.filter((obj) => obj.timeStart <= checkTime);
    if (tzs && tzs.length > 0) {
      tzs.sort((a, b) => b.timeStart - a.timeStart);
      return tzs[0].dst == '1';
    }
  }
  return false;
}
export function isInDstOld(
  shopInfo:
    | {
        timezoneInfos: TimezoneFullDto[];
        shopInfo?: { applyDst?: number };
      }
    | undefined,
  checkTime: number
): boolean {
  if (!shopInfo?.shopInfo?.applyDst) {
    return false;
  }
  if (shopInfo?.timezoneInfos) {
    const tzs = shopInfo?.timezoneInfos?.filter(
      (obj) => obj.timeStart <= checkTime
    );
    if (tzs && tzs.length > 0) {
      tzs.sort((a, b) => b.timeStart - a.timeStart);
      return tzs[0].dst == '1';
    }
  }
  return false;
}

export function getInDstLength(
  applyDst: number,
  dstShiftSchedule: number,
  dstLength: number,
  timezoneInfos: TimezoneFullDto[],
  checkingTime?: number
): number {
  const isDST = isInDst(applyDst, timezoneInfos, checkingTime);
  return dstShiftSchedule ? (isDST ? dstLength ?? 0 : 0) : 0;
}

export const getTimezoneOffset = (
  timezoneInfos: TimezoneFullDto[],
  checkingTime = 0
) => {
  if (checkingTime <= 0) {
    checkingTime = Date.now() / 1000;
  }
  const foundTzs = timezoneInfos?.filter(
    (obj) => obj.timeStart <= checkingTime
  );
  let offset = 0;
  if (foundTzs && foundTzs.length > 0) {
    foundTzs.sort((a, b) => b.timeStart - a.timeStart);
    offset = foundTzs[0].gmtOffset;
  }
  return offset;
};

export const getTimezone = (
  timezoneInfos: TimezoneFullDto[],
  checkingTime = 0
) => {
  const offset = getTimezoneOffset(timezoneInfos, checkingTime);
  return getTimeZoneByOffset(offset);
};
