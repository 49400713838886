import { DateTime } from 'luxon';
import mixpanel, { Dict } from 'mixpanel-browser';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import Script from 'next/script';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { onCLS, onFID, onLCP } from 'web-vitals';
import { useGetBasicShopQuery } from '../../redux/apis/ShopApi';
import {
  OnboardingStatus,
  OnboardingTypeId,
} from '../../types/onboarding-types';
import Clarity from '../../utils/clarity';
import { trimString } from '../../utils/helpers';

interface AnalyticsContextValue {
  registerProperties(superProperties: Dict, profileProperties: Dict): void;
  trackEvent(event: string, param?: Dict): void;
  trackEvents(events: { event: string; param?: Dict }[]): void;
}

export const AnalyticsContext = createContext<AnalyticsContextValue>({
  registerProperties: function (
    superProperties: Dict,
    profileProperties: Dict
  ) {
    // do nothing;
  },
  trackEvent: function (event: string, param?: Dict): void {
    // do nothing;
  },
  trackEvents: function (events: { event: string; param?: Dict }[]): void {
    // do nothing;
  },
});

export function useAnalytics(): AnalyticsContextValue {
  return useContext(AnalyticsContext);
}

interface Props {
  shop: string;
  pathname: string;
  isProd: boolean;
  gaMeasurementId: string;
  clarityProjectId: string;
  mixPanelKey: string;
}

export default function AnalyticsProvider({
  shop,
  pathname,
  isProd,
  gaMeasurementId,
  clarityProjectId,
  mixPanelKey,
  children,
}: PropsWithChildren<Props>) {
  const vitalInitialized = useRef(false);
  const mixpanelInitialized = useRef(false);
  const pendingSuperProperties = useRef<Dict>();
  const pendingProfileProperties = useRef<Dict>();
  const pendingEvents = useRef<{ event: string; param?: Dict }[]>([]);

  const registerProperties = useCallback(
    (superProperties: Dict, profileProperties: Dict) => {
      if (mixpanelInitialized.current) {
        mixpanel.register(superProperties);
        mixpanel.people.set(profileProperties);
      } else {
        pendingSuperProperties.current = superProperties;
        pendingProfileProperties.current = profileProperties;
      }
    },
    []
  );
  const trackEvent = useCallback(
    (event: string, param?: Dict) => {
      const data = {
        shop,
        datetime_ict: DateTime.now().setZone('UTC+07:00').toISO(),
        page_location: pathname,
        ...(param ?? {}),
      };
      if (typeof gtag !== 'undefined') {
        gtag?.('event', event, data);
      }
      if (mixpanelInitialized.current) {
        mixpanel.track(event, data);
      } else {
        pendingEvents.current.push({ event, param });
      }
    },
    [pathname, shop]
  );
  const trackEvents = useCallback(
    (events: { event: string; param?: Dict }[]) => {
      events.filter(Boolean).forEach(({ event, param }) => {
        trackEvent(event, param);
      });
    },
    [trackEvent]
  );

  useEffect(() => {
    Clarity.init(clarityProjectId);
    Clarity.identify(shop, undefined, pathname, shop);
  }, [clarityProjectId, pathname, shop]);

  useEffect(() => {
    mixpanel.init(mixPanelKey ?? '9cb94551b4d9b00d75ee71ebcbaf6292', {
      debug: !isProd,
      verbose: !isProd,
      track_pageview: true,
      persistence: 'localStorage',
    });
    mixpanel.identify(shop);

    mixpanelInitialized.current = true;

    if (pendingSuperProperties.current && pendingProfileProperties.current) {
      registerProperties(
        pendingSuperProperties.current,
        pendingProfileProperties.current
      );
    }
    pendingEvents.current
      .splice(0, pendingEvents.current.length)
      .forEach(({ event, param }) => {
        mixpanel.track(event, param);
      });
  }, [isProd, mixPanelKey, registerProperties, shop]);

  useEffect(() => {
    if (vitalInitialized.current) {
      return;
    }
    vitalInitialized.current = true;

    const { publicRuntimeConfig } = getConfig();

    onCLS((metric) => {
      if (publicRuntimeConfig.DEV_MENU_ENABLED) {
        console.log('web-vitals', metric.name, metric);
      }

      if (metric.value > 0.1) {
        const data = {
          ...metric,
          ...Object.assign(
            {},
            metric.entries
              .map(({ sources, value }) => {
                return trimString(
                  `[${value}] ${sources
                    ?.map(({ node }) => {
                      return node?.textContent;
                    })
                    .filter(Boolean)
                    .join(',')}`,
                  255
                );
              })
              .filter(Boolean)
          ),
        };
        trackEvent('CLS-' + metric.rating, data);
      }
    });
    onFID((metric) => {
      if (publicRuntimeConfig.DEV_MENU_ENABLED) {
        console.log('web-vitals', metric.name, metric);
      }

      if (metric.value > 100) {
        const data = {
          ...metric,
          ...Object.assign(
            {},
            metric.entries
              .map(({ target, duration }) => {
                return trimString(`[${duration}] ${target?.textContent}`, 255);
              })
              .filter(Boolean)
          ),
        };
        trackEvent('FID-' + metric.rating, data);
      }
    });
    onLCP((metric) => {
      if (publicRuntimeConfig.DEV_MENU_ENABLED) {
        console.log('web-vitals', metric.name, metric);
      }

      if (metric.value > 2500) {
        const data = {
          ...metric,
          ...Object.assign(
            {},
            metric.entries
              .map(({ element }) => {
                return trimString(element?.textContent, 255);
              })
              .filter(Boolean)
          ),
        };
        trackEvent('LCP-' + metric.rating, data);
      }
    });
  }, [trackEvent]);

  return (
    <AnalyticsContext.Provider
      value={{ registerProperties, trackEvent, trackEvents }}
    >
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaMeasurementId}', {
            cookie_flags: 'max-age=7200;secure;samesite=none'
          });
        `}
      </Script>

      {children}
    </AnalyticsContext.Provider>
  );
}

export function MixpanelPropertiesUpdater() {
  const { pathname } = useRouter();
  const { registerProperties } = useAnalytics();

  const { data: shopData } = useGetBasicShopQuery({});

  useEffect(() => {
    if (!shopData) {
      return;
    }

    const createdAt = DateTime.fromISO(shopData?.shopInfo?.createdAt);

    const superProperties = {
      app_plan_id: shopData?.shopInfo.subscriptionTypeId,
      shopify_plan_display_name: shopData?.shopInfo.shopifyPlan,
      datetime_ict: DateTime.now().setZone('UTC+07:00').toISO(),
      page_location: pathname,
      used_day: Math.abs(~~(createdAt.diffNow('days').days * 100000) / 100000),
    };
    const profileProperties = {
      $name: shopData?.shopInfo.domain,
      $email: shopData?.shopInfo.email,
      shop_id: shopData?.shopInfo.id,
      shop_created_at: createdAt.setZone('UTC+07:00').toISO(),
      initial_shopify_plan_display_name: shopData?.shopInfo.shopifyPlan,
      complete_onboarding: shopData?.onboardings?.some(
        (x) =>
          x.onboardingTypeId === OnboardingTypeId.AllSet &&
          x.status === OnboardingStatus.Finished
      )
        ? 'yes'
        : 'no',
      onboarding_ab_testing: shopData.shopInfo.id % 2 === 0 ? 'a' : 'b',
    };

    registerProperties(superProperties, profileProperties);
  }, [pathname, registerProperties, shopData]);

  return null;
}
