import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { MasterDataSliceType } from '../../types/popup-type';
import { ReduxState } from '../store';
import { MasterDataDto, TimezoneDto } from '../../types/master-data-type';

const initialState: MasterDataSliceType = {
  masterData: undefined,
  timezones: undefined,
};

const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,

  reducers: {
    setMasterData: (state, action: PayloadAction<MasterDataDto>) => {
      state.masterData = action.payload;
    },
    setTimezonesData: (state, action: PayloadAction<TimezoneDto[]>) => {
      state.timezones = action.payload;
    },
  },
});

export const { setMasterData } = masterDataSlice.actions;

export const selectMasterData = (state: ReduxState) =>
  state.masterData.masterData;

export const selectTimezoneData = (state: ReduxState) =>
  state.masterData.timezones;

export default masterDataSlice;
