import { ComplexAction } from '@shopify/polaris';
import { MasterDataDto, TimezoneDto } from './master-data-type';

export enum PopupType {
  INFO = 0,
  WARN = 1,
  ERROR = 2,
}

export interface PopupDto {
  type: PopupType;
  title?: string;
  message?: string;
  actions?: ComplexAction[];
}

export interface PopupSliceType {
  popups: PopupDto[];
}

export interface MasterDataSliceType {
  masterData?: MasterDataDto;
  timezones?: TimezoneDto[];
}
