import Image, { ImageProps } from 'next/image';
import googleCloudStorageImageLoader from '../../utils/image-loader';

export default function NextImage({ alt, src, ...others }: ImageProps) {
  return (
    <Image
      alt={alt}
      src={src || '/image/icons/No-photo.svg'}
      loader={googleCloudStorageImageLoader}
      {...others}
      suppressHydrationWarning
    />
  );
}
