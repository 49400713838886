import { ServiceDto } from './service-types';

export enum OnboardingStatus {
  None = 0,
  Finished = 1,
}

export enum OnboardingTypeId {
  Welcome = 1,
  EnableMeetyWidget = 2,
  CreateFirstService = 3,
  SetupBookingType = 4,
  SetupTimeSlots = 5,
  FirstServiceAdded = 6,
  ChoosePricingOption = 7,
  AllSet = 8,
}

export const OnboardingStep = {
  [OnboardingTypeId.Welcome]: {
    None: 0,
    Step1: 1,
  },
  [OnboardingTypeId.EnableMeetyWidget]: {
    None: 0,
    Step1: 1,
  },
  [OnboardingTypeId.CreateFirstService]: {
    None: 0,
    Step1: 1,
    Step2: 2,
  },
  [OnboardingTypeId.SetupBookingType]: {
    None: 0,
    Step1: 1,
  },
  [OnboardingTypeId.SetupTimeSlots]: {
    None: 0,
    Step1: 1,
  },
  [OnboardingTypeId.FirstServiceAdded]: {
    None: 0,
  },
  [OnboardingTypeId.ChoosePricingOption]: {
    None: 0,
    Step1: 1,
  },
  [OnboardingTypeId.AllSet]: {
    None: 0,
    Step1: 1,
  },
} as const;

export interface OnboardingTypeDto {
  id: OnboardingTypeId;
  name: string;
  description: string;
  status: boolean;
  required: boolean;
  order: number;
  ab: ABTestingPlan;
}

export enum ABTestingPlan {
  BOTH = 0,
  A_ONLY = 1,
  B_ONLY = 2,
}

export interface ShopOnboardingDto {
  id?: number;
  onboardingTypeId: OnboardingTypeId;
  step: number;
  status: number;
  param?: string;
}

export interface OnboardingSliceType {
  currentOnboarding: ShopOnboardingDto;
  currentServices: ServiceDto[];
}
