import {
  Action,
  AnyAction,
  Middleware,
  ThunkAction,
  ThunkMiddleware,
  configureStore,
} from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import {
  nextReduxCookieMiddleware,
  wrapMakeStore,
} from 'next-redux-cookie-wrapper';
import { createWrapper } from 'next-redux-wrapper';
import { AppContext, AppInitialProps } from 'next/app';
import getConfig from 'next/config';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  type TypedUseSelectorHook,
} from 'react-redux';
import logger from 'redux-logger';
import { mainConfig } from '../configs/main-config';
import { createAuthenticatedFetch } from '../utils/authenticatedFetch';
import { isServer } from '../utils/is-server';
import { AuthenticatedFetch, appApi } from './apis/BaseApi';
import rootReducer from './reducer';
import navigationHistorySlice from './slices/navigationHistorySlice';
import settingsSlice from './slices/settingsSlice';
import masterDataSlice from './slices/masterDataSlice';

const makeStore = wrapMakeStore(() => {
  const { publicRuntimeConfig } = getConfig();
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware()
        .prepend(
          nextReduxCookieMiddleware({
            sameSite: 'none',
            secure: true,
            expires: DateTime.now().plus({ year: 1 }).toJSDate(),
            subtrees: [
              `${masterDataSlice.name}`,
              `${settingsSlice.name}`,
              `${navigationHistorySlice.name}`,
            ],
          }),
          isServer() || !publicRuntimeConfig.DEV_MENU_ENABLED
            ? null
            : (logger as Middleware),
          appApi.middleware
        )
        .filter((x) => !!x),
    ],
    devTools: mainConfig.isDevEnv,
  });

  setupListeners(store.dispatch);

  return store;
});

export type ReduxStore = ReturnType<typeof makeStore>;
export type ReduxState = ReturnType<ReduxStore['getState']>;
export type ReduxDispatch = ReduxStore['dispatch'];
export type ReduxThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  Action
>;

export const useDispatch = () => useReduxDispatch<ReduxDispatch>();
export const useSelector: TypedUseSelectorHook<ReduxState> = useReduxSelector;

export const reduxWrapper = createWrapper<ReduxStore>(makeStore, {
  debug: mainConfig.isDevEnv,
  serializeState: (state) =>
    typeof state == 'object' ? JSON.stringify(state) : state,
  deserializeState: (state) =>
    typeof state == 'object' ? state : JSON.parse(state),
});

export function reduxWrapperGetServerSideProps<P extends NonNullable<unknown>>(
  callback: (ctx: {
    store: ToolkitStore<
      ReduxState,
      AnyAction,
      ThunkMiddleware<ReduxState, AnyAction>[]
    >;
    context: GetServerSidePropsContext;
    fetch: typeof fetch | undefined;
    shop: string;
    sub: string;
  }) => Promise<GetServerSidePropsResult<P>>
) {
  return reduxWrapper.getServerSideProps((store) => {
    return async (context) => {
      const { id_token, shop: shopFromQuery } = context.query as Record<
        string,
        string
      >;

      const [fetch, exp, shop, sub] = createAuthenticatedFetch(id_token);
      AuthenticatedFetch.set(shop ?? shopFromQuery, fetch, exp);

      return await callback({
        store,
        context,
        fetch,
        shop: shop ?? shopFromQuery,
        sub,
      });
    };
  });
}

export function reduxWrapperGetInitialAppProps<P extends AppInitialProps>(
  callback: (ctx: {
    store: ToolkitStore<
      ReduxState,
      AnyAction,
      ThunkMiddleware<ReduxState, AnyAction>[]
    >;
    context: AppContext;
    fetch: typeof fetch | undefined;
    shop: string;
  }) => Promise<P>
) {
  return reduxWrapper.getInitialAppProps((store) => {
    return async (context) => {
      const { id_token, shop: shopFromQuery } = context.ctx.query as Record<
        string,
        string
      >;

      const [fetch, exp, shop] = createAuthenticatedFetch(id_token);
      AuthenticatedFetch.set(shop ?? shopFromQuery, fetch, exp);

      return await callback({
        store,
        context,
        fetch,
        shop: shop ?? shopFromQuery,
      });
    };
  });
}
